var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from 'elemental-react';
// const DetailsBase = styled(Box)`
//   & > summary {
//     list-style: none;
//   }
//   & > summary::-webkit-details-marker {
//     display: none;
//   }
// `;
var Details = function (props) { return (_jsx(Box, __assign({ as: "details" }, props), void 0)); };
Details.displayName = 'Details';
export default Details;
