/* eslint-disable prefer-destructuring */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import colors from './colors';
import typography from './typography';
var breakpointOrder = [
    '601px',
    '769px',
    '1025px',
];
// const breakpoints = {
//   ...breakpointOrder,
//   mobile: breakpointOrder[0],
//   tablet: breakpointOrder[1],
//   desktop: breakpointOrder[2],
//   m: breakpointOrder[0],
//   t: breakpointOrder[1],
//   d: breakpointOrder[2],
// };
// FIXME: Update styled-system so breakpoint works with object on web
var breakpoints = __spreadArray([], breakpointOrder, true);
var space = [
    0, 4, 8, 16, 32, 64, 128, 256, 512,
];
export default __assign(__assign({}, typography), { space: space, colors: colors, breakpoints: breakpoints, colorSchemes: {
        light: {
            colors: {},
        },
        dark: {
            colors: {
                btn: {
                    text: '#000000',
                    textBtn: {
                        text: '#fff',
                        hoveredBg: '#1A1710',
                        focusedBg: '#0D0B08',
                        pressedBg: '#0D0B08',
                        disabledText: '#B9B9B9',
                    }
                },
            }
        }
    } });
