import { extend } from 'elemental-react';
import BaseButton from './BaseButton';
var OutlinedButton = extend(BaseButton, function () { return ({
    color: 'black',
    bg: 'white',
    borderColor: '#F4B728',
    borderStyle: 'solid',
    borderWidth: 1,
    outlined: true,
    ':hover': {
        bg: '#FFF7E5',
    },
    ':focus': {
        bg: '#FFEDC5',
    },
    ':disabled': {
        borderColor: '#E4E2E2',
        color: '#7D7D7D',
    }
}); });
export default OutlinedButton;
