/* eslint-disable prefer-destructuring */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var textStyles = {};
var fontSizes = __assign({}, [10, 12, 14, 16, 20, 24, 34, 48, 60, 96]);
// eslint-disable-next-line camelcase
var overline = fontSizes[0], caption = fontSizes[1], body_small = fontSizes[2], body = fontSizes[3], h6 = fontSizes[4], h5 = fontSizes[5], h4 = fontSizes[6], h3 = fontSizes[7], h2 = fontSizes[8], h1 = fontSizes[9];
var fontSizeKeys = {
    h1: h1,
    h2: h2,
    h3: h3,
    h4: h4,
    h5: h5,
    h6: h6,
    body: body,
    button: body,
    body_small: body_small,
    caption: caption,
    overline: overline,
};
Object.assign(fontSizes, fontSizeKeys);
var lineHeights = {
    4: fontSizes[4],
    h1: '112px',
    h2: '72px',
    h3: '56px',
    h4: '40px',
    h5: '32px',
    h6: '24px',
    body: '20px',
    button: '20px',
    body_small: '16px',
    caption: '16px',
    overline: '12px',
};
var typefaces = {
    helvetica: {
        light: 'Helvetica',
        regular: 'Helvetica',
        medium: 'Helvetica',
        semiBold: 'Helvetica',
    },
    roboto: {
        light: 'Roboto',
        regular: 'Roboto',
        medium: 'Roboto',
        semiBold: 'Roboto',
        mono: 'Roboto Mono',
    },
};
var keys = {
    primary: typefaces.roboto.mono,
    primaries: typefaces.roboto,
    secondary: typefaces.roboto.regular,
    secondaries: typefaces.roboto,
    // monospace: ...,
};
var fontWeights = {
    light: 300,
    regular: 500,
    medium: 600,
    semiBold: 700,
    bold: 'bold',
};
Object.assign(keys, {
    button: keys.primary,
});
var fonts = __assign(__assign(__assign({ typefaces: typefaces }, typefaces), { keys: keys }), keys);
export default {
    fonts: fonts,
    fontSizes: fontSizes,
    fontWeights: fontWeights,
    textStyles: textStyles,
    lineHeights: lineHeights,
};
