// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-react-buttons-mdx": () => import("./../../../src/pages/react/buttons.mdx" /* webpackChunkName: "component---src-pages-react-buttons-mdx" */),
  "component---src-pages-react-getting-started-mdx": () => import("./../../../src/pages/react/getting-started.mdx" /* webpackChunkName: "component---src-pages-react-getting-started-mdx" */),
  "component---src-pages-react-icons-mdx": () => import("./../../../src/pages/react/icons.mdx" /* webpackChunkName: "component---src-pages-react-icons-mdx" */),
  "component---src-pages-react-locales-mdx": () => import("./../../../src/pages/react/locales.mdx" /* webpackChunkName: "component---src-pages-react-locales-mdx" */),
  "component---src-pages-react-qr-codes-mdx": () => import("./../../../src/pages/react/qr-codes.mdx" /* webpackChunkName: "component---src-pages-react-qr-codes-mdx" */),
  "component---src-pages-react-theming-mdx": () => import("./../../../src/pages/react/theming.mdx" /* webpackChunkName: "component---src-pages-react-theming-mdx" */)
}

