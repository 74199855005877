var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { extend, Button as __Button } from 'elemental-react';
var _Button = function (_a) {
    var label = _a.label, children = _a.children, props = __rest(_a, ["label", "children"]);
    return (_jsx(__Button, __assign({ outlined: false, label: (label && typeof label === 'string') ? label.toUpperCase() : label }, props, { children: (children && typeof children === 'string') ? children.toUpperCase() : children }), void 0));
};
var BaseButton = extend(_Button, function () { return ({
    m: 2,
    px: '20px',
    borderRadius: 2,
    bg: 'btn.bg',
    height: 36,
    color: 'white',
    fontSize: 16,
    lineHeight: 20,
    fontFamily: 'Roboto Mono',
    fontWeight: 'bold',
    letterSpacing: 1,
}); });
export default BaseButton;
